@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background: #fafafb !important;
}

.slick-dots {
  position: relative !important;
  margin-top: 20px !important;
}

.slick-slide {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}